import { apiService } from "./ApiService";
import { createBearerToken } from "../helpers/authHelpers";
import { IAddTransactionRequest, IAutoGenerateTransactionsRequest } from "../types/Transaction";
import {
  IListItemOptions,
  ITransactionListOptions,
} from "../types/ListFilters";
import { toQueryString } from "./DocumentService";
import * as domains from "../configuration/domains";
import moment from "moment-timezone";

export const duplicateTransaction = (transactionId: string): Promise<any> => {
  return apiService
    .post(`${domains.transactionService}/t/duplicate/${transactionId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken(),
      },
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const deleteTransaction = (transactionId: string): Promise<any> => {
  return apiService
    .delete(`${domains.transactionService}/t/${transactionId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken(),
      },
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const deleteTransactions = (transactionIds: string[]): Promise<any> => {
  var transactionsStringed = toQueryStringArray(transactionIds);
  return apiService
    .delete(
      `${domains.transactionService}/t/ids/transactionIds?${transactionsStringed}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: createBearerToken(),
        },
      }
    )
    .catch((error) => {
      return error.response.data;
    });
};

const toQueryStringArray = (arr: string[]): string => {
  if (!arr || arr.length === 0) {
    return "";
  }

  let query = "";

  arr.map((a) => {
    query += "&transactionIds=" + a;
  });

  return query;
};

export const markTransactionAsPaid = (transactionId: string): Promise<any> => {
  return apiService
    .put(`${domains.transactionService}/t/${transactionId}/paid`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken(),
      },
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const markTransactionsAsPaid = (
  transactionIds: string[]
): Promise<any> => {
  var transactionsStringed = toQueryStringArray(transactionIds);
  return apiService
    .put(
      `${domains.transactionService}/t/ids/paid/transactionIds?${transactionsStringed}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: createBearerToken(),
        },
      }
    )
    .catch((error) => {
      return error.response.data;
    });
};

export const getAccounts = (type?: string, category?: string): Promise<any> => {
  return apiService
    .get(
      `${domains.transactionService}/account${type ? "?type=" + type : ""}${
        category && type
          ? "&category=" + category
          : category && !type
          ? "?category=" + category
          : ""
      }`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: createBearerToken(),
        },
      }
    )
    .catch((error) => {
      return error.response.data;
    });
};

export const updateTransaction = (
  transactionId: string,
  request: IAddTransactionRequest
): Promise<any> => {
  return apiService
    .put(`${domains.transactionService}/t/${transactionId}`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken(),
      },
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTransaction = (transactionId: string): Promise<any> => {
  return apiService
    .get(`${domains.transactionService}/t/${transactionId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken(),
      },
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTransactions = (
  options: IListItemOptions,
  transactionOptions: ITransactionListOptions
): Promise<any> => {
  let stringed = toQueryString(options);
  let stringed2 = toQueryString(transactionOptions);

  return apiService
    .get(`${domains.transactionService}?${stringed}&${stringed2}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken(),
      },
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const addTransaction = (
  request: IAddTransactionRequest
): Promise<any> => {
  return apiService
    .post(`${domains.transactionService}`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken(),
      },
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const addTransactions = (
  request: Array<IAddTransactionRequest>
): Promise<any> => {
  return apiService
    .post(`${domains.transactionService}/t/addTransactions`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken(),
      },
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const autoGenerateTransactions = (
  autoGenerateTransactionsRequest: IAutoGenerateTransactionsRequest
): Promise<any> => {
  let stringed = toQueryString(autoGenerateTransactionsRequest);

  return apiService
    .get(`${domains.transactionService}/t/autogenerate?${stringed}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken(),
      },
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTransactionsSummary = (
  options: IListItemOptions,
  transactionOptions: ITransactionListOptions
): Promise<any> => {
  let stringed = toQueryString(options);
  let stringed2 = toQueryString(transactionOptions);

  return apiService
    .get(`${domains.transactionService}/t/transactionsSummary?${stringed}&${stringed2}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken(),
      },
    })
    .catch((error) => {
      return error.response.data;
    });
};
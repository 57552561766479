import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { updateViewingIssue } from '../../../actions/issueActions'
import { updateAlertMessage } from '../../../helpers/alertHelpers'
import { toggleAddIssueFormChanged } from '../../../helpers/formStateHelpers'
import {
    addToIssueList,
    updateIssueListItem,
} from '../../../helpers/issueHelpers'
import { removeURLParameter } from '../../../helpers/locationHelpers'
import {
    isLandlord,
    isPlatformAdmin,
    isTenant,
} from '../../../helpers/roleHelpers'
import { toggleIssuePopup } from '../../../helpers/sidebarHelpers'
import { processToast } from '../../../helpers/toastHelper'
import { getContactFromPropertyId } from '../../../services/ContactService'
import { getTypesForDropdown } from '../../../services/EntityService'
import { addIssue, getIssue, updateIssue } from '../../../services/IssueService'
import { doesPropertyHaveUnits } from '../../../services/UnitService'
import { IDataResponse } from '../../../types/ApiService'
import { IAddIssueRequest, IIssueItem } from '../../../types/IssueService'
import { ISearchResult } from '../../../types/SearchService/ISearchService'
import { AddIssue } from '../../../YupValidationSchemas'
import { IAddTenantCallback } from '../../molecules/AddContactForm'
import { IDocumentUpload } from '../../molecules/AddDocumentModal'
import Buttons from '../Buttons'
import RCTextareaAutosize from '../RCTextareaAutosize'
import MaterialCheckbox from '../MaterialCheckbox'
import OutstandingChangesModal from '../OutstandingChangesModal'
import RotatingLoader from '../RotatingLoader'
import SearchContactInput from '../SearchContactInput'
import SearchPropertyInput from '../SearchPropertyInput'
import SearchUnitInput from '../SearchUnitInput'
import SelectDropdown, { IOptions } from '../SelectDropdown'
import SuccessTick from '../SuccessTick'
import styles from './AddEditIssue.module.scss'

const queryString = require('query-string')

export interface IRaiseIssueOptions {
    propertyId?: string
    contactId?: string
    tenancyId?: string
    isLandlord?: boolean
    issueId?: string
    unitId?: string
    inspectionId?: number
    assetId?: number

    // Names
    propertyName?: string
    contactName?: string
    unitName?: string
    isEditing?: boolean
    isOnPropertyPage?: boolean

    redirectUrl?: string
}

interface IProps extends RouteComponentProps {
    history: any
    location: any
    match: any
    isEditing?: boolean
    options?: IRaiseIssueOptions
    updateViewingIssue(issue: IIssueItem): void
}

interface IReduxProps {
    issue_popup: boolean
    add_issue_form_changed: boolean
}

type Props = IProps & IReduxProps

interface IState {
    selectedTenantId: string
    selectedTenantName: string
    selectedPropertyId: string
    selectedPropertyName: string
    selectedUnitId: string
    selectedUnitName: string
    selectedCategory: string
    selectedStatus: string
    selectedInspectionId: number

    options: IRaiseIssueOptions

    categories: Array<IOptions>
    status: Array<IOptions>

    title: string
    description: string
    file: File

    isLoading: boolean
    isSuccess: boolean

    issueItem: IIssueItem
    userEditedTitle: boolean

    shareWithTenant: boolean
    shareWithPropertyManager: boolean
    shareWithLandlord: boolean

    isContactFromProperty: boolean
    selectedPropertyIdHasUnits: boolean

    hasChanges: boolean
    outstandingChangesModal: boolean
}

let initialState = {
    selectedCategory: '',
    selectedStatus: '',
    title: '',
    description: '',
    selectedPropertyId: '',
    selectedPropertyName: '',
    selectedTenantId: '',
    selectedTenantName: '',
    selectedUnitId: '',
    selectedUnitName: '',
    selectedInspectionId: null,
}

class AddEditIssue extends React.Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            ...initialState,

            options: null,
            status: [],
            file: null,
            categories: [],

            isLoading: false,
            isSuccess: false,

            issueItem: null,
            userEditedTitle: false,

            shareWithLandlord: false,
            shareWithPropertyManager: false,
            shareWithTenant: true,
            isContactFromProperty: false,
            selectedPropertyIdHasUnits: false,

            hasChanges: false,
            outstandingChangesModal: false,
        }
    }

    onSetOpenInternal = (open: boolean): void => {
        if (!open) {
            // Check if there is outstanding changes
            if (this.props.add_issue_form_changed) {
                this.setState({
                    outstandingChangesModal: true,
                })

                return
            }
        }

        toggleIssuePopup(false)
    }

    onCloseChangesModal = (closeSidebar: boolean): void => {
        this.setState({
            outstandingChangesModal: false,
        })

        if (closeSidebar) {
            toggleAddIssueFormChanged(false)
            toggleIssuePopup(false)
        }
    }

    checkForChanges = (): void => {
        if (this.state != initialState) {
            toggleAddIssueFormChanged(true)
        } else {
            toggleAddIssueFormChanged(false)
        }
    }

    onClearProperty = (): void => {
        this.setState({
            selectedPropertyId: '',
            selectedPropertyName: '',
        })
    }

    onClearContact = (): void => {
        this.setState({
            selectedTenantId: '',
            selectedTenantName: '',
        })
    }

    getEntities = (): void => {
        // Categories
        getTypesForDropdown('821F084B-E9BE-4F42-B331-0327C1D8D419').then(
            (resp) => {
                if (resp && resp.status && resp.data) {
                    this.setState({
                        categories: resp.data,
                    })
                }
            }
        )

        // Status
        getTypesForDropdown('8C31AE99-426D-4BFD-9C92-80297591DBA3').then(
            (resp) => {
                if (resp && resp.status && resp.data) {
                    this.setState({
                        status: resp.data,
                    })
                }
            }
        )
    }

    useOptions = (): void => {
        if (!this.state.options) {
            return
        }

        // If no issue, use any options provided
        if (!this.state.options.issueId) {
            if (
                this.state.options.propertyId &&
                this.state.options.propertyName
            ) {
                this.setState({
                    selectedPropertyId: this.state.options.propertyId,
                    selectedPropertyName: this.state.options.propertyName,
                })
            }

            if (
                this.state.options.contactId &&
                this.state.options.contactName
            ) {
                this.setState({
                    selectedTenantId: this.state.options.contactId,
                    selectedTenantName: this.state.options.contactName,
                })
            }
        }

        if (this.state.options.unitId && this.state.options.unitName) {
            this.setState({
                selectedUnitId: this.state.options.unitId,
                selectedUnitName: this.state.options.unitName,
            })
        }

        if (this.state.options.propertyId && !this.state.options.contactId) {
            getContactFromPropertyId(this.state.options.propertyId).then(
                (resp) => {
                    if (resp && resp.status == 200 && resp.data) {
                        this.setState({
                            selectedTenantId: resp.data.value,
                            selectedTenantName: resp.data.label,
                            isContactFromProperty: true,
                        })
                    }
                }
            )

            if (this.state.options.propertyId) {
                doesPropertyHaveUnits(this.state.options.propertyId).then(
                    (resp) => {
                        if (resp && resp.status == 200 && resp.data) {
                            this.setState({
                                selectedPropertyIdHasUnits: resp.data == true,
                            })
                        }
                    }
                )
            }
        }
    }

    getOptions = (): void => {
        let options = queryString.parse(this.props.location.search).issueOptions
        if (options) {
            let option: IRaiseIssueOptions = JSON.parse(options)

            this.setState(
                {
                    options: option,
                    isLoading: true,
                },
                () => this.useOptions()
            )

            if (option.issueId) {
                getIssue(option.issueId).then((resp) => {
                    let response: IDataResponse<IIssueItem> = resp.data
                    if (response.isSuccess && response.data) {
                        let data: IIssueItem = response.data
                        initialState = {
                            selectedCategory: data.categoryId,
                            selectedStatus: data.statusId,
                            title: data.title,
                            description: data.description,
                            selectedPropertyId: data.propertyId,
                            selectedPropertyName: data.propertyName,
                            selectedTenantId: data.contactId,
                            selectedTenantName: data.contactName,
                            selectedUnitId: data.unitId,
                            selectedUnitName: data.unitName,
                            selectedInspectionId: data.inspectionId,
                        }
                        this.setState({
                            selectedCategory: data.categoryId,
                            selectedStatus: data.statusId,
                            title: data.title,
                            description: data.description,
                            selectedPropertyId: data.propertyId,
                            selectedPropertyName: data.propertyName,
                            selectedTenantId: data.contactId,
                            selectedTenantName: data.contactName,
                            selectedUnitId: data.unitId,
                            selectedUnitName: data.unitName,
                            selectedInspectionId: data.inspectionId,
                        })
                    } else {
                        updateAlertMessage(
                            response.errorMessage,
                            true,
                            0,
                            false
                        )
                        this.setState({ isLoading: false })
                    }

                    if (this.state.selectedPropertyId) {
                        doesPropertyHaveUnits(
                            this.state.selectedPropertyId
                        ).then((resp) => {
                            if (resp && resp.status == 200 && resp.data) {
                                this.setState({
                                    selectedPropertyIdHasUnits:
                                        resp.data == true,
                                })
                            }
                        })
                    }
                })
            }
            this.setState({
                isLoading: false,
            })
        }

        if (this.props.options) {
            if (this.props.options.issueId) {
                this.setState({
                    options: this.props.options,
                })

                getIssue(this.props.options.issueId).then((resp) => {
                    let response: IDataResponse<IIssueItem> = resp.data
                    if (response.isSuccess && response.data) {
                        let data: IIssueItem = response.data
                        this.setState({
                            selectedCategory: data.category,
                            selectedStatus: data.status,
                            title: data.title,
                            description: data.description,
                            selectedPropertyId: data.propertyId,
                            selectedPropertyName: data.propertyName,
                            selectedTenantId: data.contactId,
                            selectedTenantName: data.contactName,
                        })
                    } else {
                        updateAlertMessage(
                            response.errorMessage,
                            true,
                            0,
                            false
                        )
                        this.setState({ isLoading: false })
                    }
                })
            }
        }

        let removeQuery = removeURLParameter(
            this.props.location.search,
            'issueOptions'
        )
        this.props.history.push({
            search: removeQuery,
        })

        this.setState({
            isLoading: false,
        })
    }

    componentWillReceiveProps(newProps: IProps): void {
        let next = queryString.parse(
            newProps.location.search
        ).addContactCallback
        if (next) {
            let data: IAddTenantCallback = JSON.parse(next)

            if (data.contactId != this.state.selectedTenantId) {
                this.setState((prevState) => ({
                    selectedTenantId: data.contactId,
                    selectedTenantName: data.name,
                }))

                let query = removeURLParameter(
                    this.props.location.search,
                    'addContactCallback'
                )
                this.props.history.push({
                    search: query,
                })
            }
        }
    }

    componentWillUnmount(): void {
        let removeQuery = removeURLParameter(
            this.props.location.search,
            'issueOptions'
        )
        this.props.history.push({
            search: removeQuery,
        })
    }

    componentDidMount(): void {
        this.getEntities()
        this.getOptions()
    }

    handleDocumentAdded = (file: File): void => {
        this.setState({
            file,
        })
    }

    onSelectTenant = (selected: ISearchResult): void => {
        this.setState(
            {
                selectedTenantName: selected.label,
                selectedTenantId: selected.value,
            },
            () => this.checkForChanges()
        )
    }

    onSelectProperty = (selected: IOptions): void => {
        this.setState(
            {
                selectedPropertyId: selected.value,
                selectedPropertyName: selected.label,
            },
            () => this.checkForChanges()
        )

        doesPropertyHaveUnits(selected.value).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                this.setState({
                    selectedPropertyIdHasUnits: resp.data == true,
                })
            }
        })
    }

    handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value
        this.setState({ [field]: value } as Pick<IState, any>, () =>
            this.checkForChanges()
        )

        if (field == 'title') {
            this.setState(
                {
                    userEditedTitle: true,
                },
                () => this.checkForChanges()
            )
        }
    }

    shouldChangeTitle = (): boolean => {
        if (!this.state.userEditedTitle) {
            return true
        }

        return false
    }

    onSubmit = (): void => {
        this.setState({
            isLoading: true,
        })

        if (this.state.options && this.state.options.issueId) {
            updateIssue(
                {
                    title: this.state.title,
                    description: this.state.description,
                    category: this.state.selectedCategory,
                    status: this.state.selectedStatus,
                    propertyId: this.state.selectedPropertyId,
                    contactId: this.state.selectedTenantId,
                    unitId: this.state.selectedUnitId,
                    inspectionId:
                        this.state.options && this.state.options.inspectionId,
                },
                this.state.options.issueId
            ).then((resp) => {
                if (resp && resp.status == 200) {
                    this.setState(
                        {
                            isLoading: false,
                            isSuccess: true,
                        },
                        () => {
                            updateIssueListItem(resp.data)
                            this.props.updateViewingIssue(resp.data)
                            setTimeout(() => {
                                toggleIssuePopup(false)
                            }, 2000)
                        }
                    )
                }
            })
            this.setState({
                isLoading: false,
            })
            return
        }

        var formData = new FormData()
        if (this.state.file) {
            formData.append('File', this.state.file)
        }

        let request: IAddIssueRequest = {
            title: this.state.title,
            description: this.state.description,
            category: this.state.selectedCategory || null,
            isTenantRaising: isTenant(),
            propertyId: this.state.selectedPropertyId,
            contactId: this.state.selectedTenantId,
            unitId: this.state.selectedUnitId,
            inspectionId: this.state.options && this.state.options.inspectionId,
            assetId: this.state.options && this.state.options.assetId,
            shareWithLandlord: this.state.shareWithLandlord,
            shareWithPropertyManager: this.state.shareWithPropertyManager,
            shareWithTenant: this.state.shareWithTenant,
        }

        addIssue(request, formData).then((resp) => {
            if (resp && resp.status == 200) {
                let response: IDataResponse<IIssueItem> = resp.data
                processToast(response)

                this.setState({
                    isLoading: false,
                    isSuccess: true,
                })

                addToIssueList(response.data)
                toggleIssuePopup(false)

                if (this.state.options && this.state.options.redirectUrl) {
                    this.props.history.push(this.state.options.redirectUrl)
                    return
                }

                if (this.state.options && this.state.options.assetId) {
                    this.props.history.push(
                        `/dashboard/asset/view/${this.state.options.assetId}/issues/${response.data.issueId}`
                    )
                    return
                }

                if (this.state.options && this.state.options.unitId) {
                    this.props.history.push(
                        `/dashboard/unit/view/${this.state.options.unitId}/issues/${response.data.issueId}`
                    )
                    return
                }

                if (this.state.options && this.state.options.inspectionId) {
                    this.props.history.push(
                        `/dashboard/inspection/view/${this.state.options.inspectionId}/issues/${response.data.issueId}`
                    )
                    return
                }

                if (this.state.options && this.state.options.redirectUrl) {
                    this.props.history.push(this.state.options.redirectUrl)
                    return
                }

                if (this.state.options && this.state.options.isOnPropertyPage) {
                    this.props.history.push(
                        `/dashboard/property/${this.state.selectedPropertyId}/issues/${response.data.issueId}`
                    )
                    return
                }

                this.props.history.push(
                    `/dashboard/issues/${response.data.issueId}`
                )
            }
        })
    }

    handleDescriptionChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value: string = event.currentTarget.value
        this.setState({ description: value } as Pick<IState, any>)
    }

    onCancel = (): void => {
        let removeQuery = removeURLParameter(
            this.props.location.search,
            'issueOptions'
        )
        this.props.history.push({
            search: removeQuery,
        })

        this.props.history.goBack()
    }

    render() {
        if (
            this.state.options &&
            this.state.options.issueId &&
            !this.state.title &&
            !this.state.description
        ) {
            return null
        }

        return this.state.isLoading ? (
            <div>
                <RotatingLoader text="" loading={true} />
                <p className={styles.success}>Raising issue...</p>
            </div>
        ) : this.state.isSuccess ? (
            <div>
                <SuccessTick />
                <p className={styles.success}>
                    Issue{' '}
                    {this.state.options && this.state.options.issueId
                        ? 'updated'
                        : 'raised succesfully'}
                </p>
            </div>
        ) : (
            <div className={styles.modalBody}>
                <OutstandingChangesModal
                    open={this.state.outstandingChangesModal}
                    onCloseChangesModal={this.onCloseChangesModal}
                />
                <Formik
                    initialValues={{
                        status: this.state.selectedStatus,
                        category: this.state.selectedCategory,
                        title: this.state.title,
                        description: this.state.description,
                    }}
                    enableReinitialize={true}
                    validationSchema={AddIssue}
                    onSubmit={(values: any, { setSubmitting }) => {
                        this.setState(
                            {
                                title: values.title,
                                description: values.description,
                            },
                            () => this.onSubmit()
                        )
                    }}
                >
                    {(props) => (
                        <Form>
                            {isLandlord() && (
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <div className={styles.label}>
                                            Property
                                        </div>
                                        <SearchPropertyInput
                                            onClear={() =>
                                                this.setState(
                                                    {
                                                        selectedPropertyId:
                                                            null,
                                                        selectedPropertyIdHasUnits:
                                                            false,
                                                        selectedUnitId: null,
                                                        selectedUnitName: null,
                                                    },
                                                    () => this.checkForChanges()
                                                )
                                            }
                                            selectedPropertyId={
                                                this.state.selectedPropertyId
                                            }
                                            selectedPropertyName={
                                                this.state.selectedPropertyName
                                            }
                                            onSelect={this.onSelectProperty}
                                        />
                                    </div>
                                    <div className={styles.column}>
                                        <div className={styles.label}>
                                            Contact at property{' '}
                                            {this.state
                                                .isContactFromProperty && (
                                                <span
                                                    className={
                                                        styles.contactProperty
                                                    }
                                                >
                                                    (Contact picked up from
                                                    latest Tenancy)
                                                </span>
                                            )}
                                        </div>
                                        <SearchContactInput
                                            onAdd={() => null}
                                            allowNewContact
                                            newContactType="Contact"
                                            contactType={[
                                                '921CCFFF-7386-400D-804C-B638121A75D2',
                                            ]}
                                            onClear={() =>
                                                this.setState(
                                                    {
                                                        selectedTenantId: null,
                                                        isContactFromProperty:
                                                            false,
                                                    },
                                                    () => this.checkForChanges()
                                                )
                                            }
                                            selectedId={
                                                this.state.selectedTenantId
                                            }
                                            selectedName={
                                                this.state.selectedTenantName
                                            }
                                            onSelect={this.onSelectTenant}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className={styles.row}>
                                <div className={styles.column}>
                                    {this.state.selectedPropertyId &&
                                        this.state
                                            .selectedPropertyIdHasUnits && (
                                            <div className={styles.search}>
                                                <div>
                                                    <div
                                                        className={styles.label}
                                                    >
                                                        Unit
                                                    </div>
                                                    <SearchUnitInput
                                                        propertyId={
                                                            this.state
                                                                .selectedPropertyId
                                                        }
                                                        onClear={() =>
                                                            this.setState(
                                                                {
                                                                    selectedUnitId:
                                                                        null,
                                                                    selectedUnitName:
                                                                        null,
                                                                },
                                                                () =>
                                                                    this.checkForChanges()
                                                            )
                                                        }
                                                        selectedUnitId={
                                                            this.state
                                                                .selectedUnitId &&
                                                            this.state
                                                                .selectedUnitId
                                                        }
                                                        selectedUnitName={
                                                            this.state
                                                                .selectedUnitName &&
                                                            this.state
                                                                .selectedUnitName
                                                        }
                                                        onSelect={(
                                                            option: IOptions
                                                        ) =>
                                                            this.setState(
                                                                {
                                                                    selectedUnitId:
                                                                        option.value,
                                                                    selectedUnitName:
                                                                        option.label,
                                                                },
                                                                () =>
                                                                    this.checkForChanges()
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>

                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <div className={styles.label}>Category</div>
                                    <SelectDropdown
                                        selectedId={this.state.selectedCategory}
                                        showDefaultOption
                                        data={this.state.categories}
                                        placeholder="Issue category..."
                                        onSelect={(id: IOptions) => {
                                            if (this.shouldChangeTitle()) {
                                                this.setState({
                                                    title: id.label,
                                                })
                                            }
                                            this.setState(
                                                {
                                                    selectedCategory: id.value,
                                                },
                                                () => this.checkForChanges()
                                            )
                                        }}
                                        isSearchable={true}
                                    />
                                </div>
                                <div className={styles.column}>
                                    <div className={styles.label}>Title*</div>
                                    <Field
                                        name="title"
                                        className={styles.input}
                                        placeholder="Enter title..."
                                        onChange={this.handleChange}
                                    />
                                    <span className={styles.errorMessage}>
                                        <ErrorMessage
                                            className={styles.errorMessage}
                                            name="title"
                                        />
                                    </span>
                                </div>
                            </div>

                            {false && isLandlord() && (
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <div className={styles.label}>
                                            Status
                                        </div>
                                        <SelectDropdown
                                            selectedId={
                                                this.state.selectedStatus
                                            }
                                            showDefaultOption
                                            data={this.state.status}
                                            placeholder="Status..."
                                            onSelect={(id: IOptions) =>
                                                this.setState({
                                                    selectedStatus: id.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className={styles.column}>
                                        <div className={styles.label}>
                                            Priority (Not yet implemented)
                                        </div>
                                        <SelectDropdown
                                            disabled
                                            data={[
                                                {
                                                    label: '',
                                                    value: '',
                                                },
                                            ]}
                                            placeholder="Priority..."
                                            onSelect={(id: IOptions) =>
                                                this.setState({
                                                    selectedStatus: id.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            )}

                            {((this.state.options &&
                                !this.state.options.issueId) ||
                                !this.state.options) && (
                                <div className={styles.chatInput}>
                                    <div className={styles.label}>
                                        Description*
                                    </div>
                                    <RCTextareaAutosize
                                        handleDocumentAdded={
                                            this.handleDocumentAdded
                                        }
                                        handleChange={(e: any) =>
                                            this.handleDescriptionChange(e)
                                        }
                                        placeholder="Provide more information and start a chat"
                                        maxRows={3}
                                        maxLength={500}
                                    />
                                    <span className={styles.errorMessage}>
                                        <ErrorMessage
                                            className={styles.errorMessage}
                                            name="description"
                                        />
                                    </span>
                                </div>
                            )}

                            {false && (
                                <div className={styles.sharingOptions}>
                                    <span className={styles.label}>
                                        Issue shared with:
                                    </span>

                                    <div className={styles.sharingRow}>
                                        {(isLandlord() ||
                                            isPlatformAdmin()) && (
                                            <div className={styles.column}>
                                                <MaterialCheckbox
                                                    labelText="Tenant"
                                                    checked={
                                                        this.state
                                                            .shareWithTenant
                                                    }
                                                    onChange={() =>
                                                        this.setState({
                                                            shareWithTenant:
                                                                !this.state
                                                                    .shareWithTenant,
                                                        })
                                                    }
                                                />
                                            </div>
                                        )}

                                        {false && (
                                            <div>
                                                <div className={styles.column}>
                                                    <MaterialCheckbox
                                                        labelText="Property Manager"
                                                        checked={true}
                                                        onChange={null}
                                                    />
                                                </div>

                                                <div className={styles.column}>
                                                    <MaterialCheckbox
                                                        labelText="Landlord"
                                                        checked={true}
                                                        onChange={null}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            <div className={styles.buttons}>
                                <Buttons
                                    buttons={[
                                        {
                                            displayType: 'cancel',
                                            elementType: 'button',
                                            onClick: () =>
                                                this.onSetOpenInternal(false),
                                        },
                                        {
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () => props.submitForm(),
                                        },
                                    ]}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    issue_popup: state.app.popups.issue_popup,
    add_issue_form_changed: state.app.form_states.add_issue_form_changed,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            updateViewingIssue,
        },
        dispatch
    )

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddEditIssue)
)

// Import React
import React, { useEffect, useState } from "react";
import { Route, Redirect, useRouteMatch, useParams } from "react-router-dom";
import styles from "./TransactionsGridContainer.module.scss";
import TransactionsGrid from "../TransactionsGrid";
import { financialYearStart, financialYearEnd, yearStart, yearEnd } from "../../../../global/variables/_dates";
import { IAddTransactionReferer } from "../../../atoms/AddEditTransaction";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { IAddEditTransactionOptions } from "../../../atoms/AddEditTransactionModal";
import { ITransactionGridFilterOptions } from "../TransactionsGrid/_types";
import { getProfileFromProfileId } from "../../../../services/ProfileUpdateService";
import { IUserProfileResponse } from "../../../../types/ProfileService/IProfile";
import { getPrimaryTenancyInformation } from "../../../../services/TenancyService";
import { getUnitByUnitId } from "../../../../services/UnitService";
import { getPrimaryPropertyDetails } from "../../../../services/PropertyService";

interface props {
  context: string;
}

const TransactionsGridContainer = ({ context }: props) => {
  // Redux 
  const { viewing_tenancy_detail } = useSelector((state: RootState) => state.property.tenancy);
  const { property_data  } = useSelector((state: RootState) => state.property.property_data);
  const { viewing_unit  } = useSelector((state: RootState) => state.unit);

  const routeMatch = useRouteMatch();
  const params = useParams<any>();
  
  const [addSettings, setAddSetings] = useState<IAddEditTransactionOptions>();
  const [dataFilterSettings, setDataFilterSettings] = useState<ITransactionGridFilterOptions>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {

  }, []);

  useEffect(()=>{
    if(context=='contact' && params.contactId){
      setLoading(true);
      getProfileFromProfileId(params.contactId).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
              setUserParameters(resp.data);
            }
            setLoading(false);
        }
      )
    }else if(context == 'tenancy'){
      if(viewing_tenancy_detail){
        setTenancyParameters(viewing_tenancy_detail);
      }else if(params.tenancyId){
        setLoading(true);
        getPrimaryTenancyInformation(params.tenancyId).then((resp) => {
          if (resp && resp.status == 200 && resp.data) {
            setTenancyParameters(resp.data);
          }
          setLoading(false);
        })
      }
    }else if(context=='unit'){
      if(viewing_unit){
        setUnitParameters(viewing_unit);
      }else if(params.unitId){
        setLoading(true);
        getUnitByUnitId(params.unitId).then((resp) => {
          if (resp && resp.status == 200 && resp.data) {
            setUnitParameters(resp.data);
          }
          setLoading(false);
        })
      }
    }else if(context=='property'){
      console.debug(`MohTransactionsGrid - TransactionsGridContainer - property params: ${params.propertyId}`)
      if(property_data){
        setPropertyParameters(property_data);
      }else if(params.propertyId){
        setLoading(true);
        getPrimaryPropertyDetails(params.propertyId).then((resp) => {
          if (resp && resp.status == 200 && resp.data) {
            setPropertyParameters(resp.data.data);
          }
          setLoading(false);
        })
      }
    }else if(context =='none'){
      setDataFilterSettings({
        filtersLoaded: true
      })
    }
  },[context])

  const setPropertyParameters = (property) => {
    setAddSetings({
      referer: IAddTransactionReferer.Property,
      propertyId: property.propertyId,
      propertyName: property.propertyName
    })

    setDataFilterSettings({
      propertyId: property.propertyId,
      isProperty: true,
      filtersLoaded: true
    })
  }

  const setUnitParameters = (unit) => {
    setAddSetings({
      referer: IAddTransactionReferer.Unit,
      unitId: unit.unitId,
      unitName: unit.unitName,
      propertyId: unit.propertyId,
      propertyName: unit.propertyName
    })

    setDataFilterSettings({
      unitId: unit.unitId,
      filtersLoaded: true
    })
  }

  const setTenancyParameters = (tenancy) => {
    setAddSetings({
      referer: IAddTransactionReferer.Tenancy,
      propertyId: tenancy.propertyId,
      propertyName: tenancy.propertyName,
      unitId: tenancy.unitId,
      unitName: tenancy.unitName,
      tenancyId: tenancy.tenancyId,
      contactId: tenancy.contactId
    })
  
    setDataFilterSettings({
      tenancyId: tenancy.tenancyId,
      filtersLoaded: true
    })
  }

  const setUserParameters = (userProfile: IUserProfileResponse) => {
    setAddSetings({
      referer: IAddTransactionReferer.Contact,
      contactId: params.contactId,
      contactName: userProfile.profile.forename = ' ' + userProfile.profile.surname
    })
  
    setDataFilterSettings({
      contactId: params.contactId,
      isProperty: true,
      filtersLoaded: true
    })
  }

  if(loading){
    return <div></div>
  }

  return (
    <div className={styles.page}>
      <Route
        path={`${routeMatch.url}`}
        exact
        render={() => <Redirect to={`${routeMatch.url}/income`} />}
      />

      <Route
        path={`${routeMatch.path}/income`}
        render={(props) => (
          <TransactionsGrid
            config={{
              sortingSettings: {
                columns: [
                  {field: 'invoiceDate', direction: 'Descending'},
                  {field: 'invoiceMonthYear', direction: 'Descending'}
                ]
              },
              dataFilterSettings: {
                ...dataFilterSettings,
                transactionType: 'income',
              },
              addSettings: addSettings,
              incomeExpenditureDropdown: false,
            }}
            tab="Income"
            context={context}
          />
        )}
      />
      <Route
        path={`${routeMatch.path}/expense`}
        render={(props) => (
          <TransactionsGrid
            config={{
              sortingSettings: {
                columns: [
                  {field: 'invoiceDate', direction: 'Descending'},
                  {field: 'invoiceMonthYear', direction: 'Descending'}
                ]
              },
              dataFilterSettings: {
                ...dataFilterSettings,
                transactionType: 'expense',
              },
              addSettings: addSettings,
              incomeExpenditureDropdown: false,
            }}
            tab="Expense"
            context={context}
          />
        )}
      />
      <Route
        path={`${routeMatch.path}/pl`}
        render={(props) => (
          <TransactionsGrid
            config={{
              sortingSettings: {
                columns: [
                  {field: 'invoiceDate', direction: 'Descending'},
                  {field: 'invoiceMonthYear', direction: 'Descending'}
                ]
              },
              dataFilterSettings: dataFilterSettings,
              addSettings: addSettings,
              incomeExpenditureDropdown: false,
            }}
            dateRangeStart={yearStart}
            dateRangeEnd={yearEnd}
            tab="PL"
            context={context}
          />
        )}
      />
      <Route
        path={`${routeMatch.path}/HMRC`}
        render={() => (
          <TransactionsGrid
            config={{
              sortingSettings: {
                columns: [
                  {field: 'invoiceDate', direction: 'Descending'},
                  {field: 'invoiceMonthYear', direction: 'Descending'}
                ]
              },
              groupSettings: ["hmrcReporting", "categoryName"],
              dataFilterSettings: dataFilterSettings,
              incomeExpenditureDropdown: true,
              addSettings: addSettings,
            }}
            dateRangeStart={financialYearStart}
            dateRangeEnd={financialYearEnd}
            tab="HMRC"
            context={context}
          />
        )}
      />
    </div>
  );
}

export default TransactionsGridContainer;
import React, { Component, createRef, useRef } from 'react'
import { withRouter, Router } from 'react-router-dom'
import {
    GridComponent,
    Inject,
    QueryCellInfoEventArgs,
    Column,
    ColumnMenu,
    CommandColumn,
    Filter,
    Page,
    Sort,
    ExcelExport,
    PdfExport,
    Group,
    Reorder,
    Resize,
    CellSelectEventArgs,
    GroupSettingsModel,
    ColumnsDirective,
    ColumnDirective,
} from '@syncfusion/ej2-react-grids'
import addIcon from '../../../../images/icons/icon_add.svg'
import {
    filterOptions,
    pageSettings,
} from '../../../../configuration/grid_syncfusion_options'
import { ODataV4Adaptor } from '@syncfusion/ej2-data'
import UserContactInfo, {
    UserContactInfoType,
} from '../../../atoms/UserContactInfo'
import ReactDOM from 'react-dom'
import history, { goToRoute } from '../../../../history'
import { selectionSettings } from './_config/selectionConfig'
import { textWrapSettings, editSettings } from './_config/columnOptions'
import { IUnitGridOptions } from './_types'
import { ExportTypeEnum } from '../../../../types/Transaction'
import styles from './UnitGrid.module.scss'
import { generateOdataSource } from './_config/dataConfig'
import { UnitDefaultTemplate } from './_templates/unitDefaultTemplate'
import UnitGridTopRow from './UnitGridTopRow'
import { ContextMenu } from '@syncfusion/ej2-react-navigations'
import { IUnitNavigationOptions } from '../../../../types/UnitService'
import { unitServiceOdataFriendly } from '../../../../configuration/domains'
import { createBearerToken } from '../../../../helpers/authHelpers'
import { removeURLParameter } from '../../../../helpers/locationHelpers'
import { updateToastMessage } from '../../../../helpers/toastHelper'
import {
    onAddUnitTenancyClick,
    onAddUnitTransactionClick,
    onDeleteRows,
} from './_helpers'
import SFDropdown from '../../../atoms/SFDropdown'
import iconAction from '../../../../images/icons/transactions/icon_action.svg'
import {
    ChangedEventArgs,
    TextBoxComponent,
} from '@syncfusion/ej2-react-inputs'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { addDocumentClicked } from '../../../atoms/SFDropdown/SFPropertyDropdown/_helpers'
import { onAddIssueClickGeneric } from '../../../atoms/SFDropdown/SFUnitDropdown/_helpers'
import IconButton from '../../../atoms/Buttons/IconButton'
const queryString = require('query-string')

interface IState {
    loading?: boolean
    hasBeenGrouped: boolean
    initialRender: boolean
    search?: string
    startDate?: Date
    endDate?: Date
    selectedType?: string
    deletingUserIds: string[]
    deleting: boolean
    availableToRentOnly: boolean
    LOADED: boolean
    hideGrid: boolean
}

interface IProps {
    config?: IUnitGridOptions
    onAdd?(): void
    onMultiAdd?(): void
    history: any
    location: any
    match: any
    dateRangeStart?: Date
    dateRangeEnd?: Date

    updateRedirectRoute?: string
    onDeleteCallbackUrl?: string

    navigationOptions?: IUnitNavigationOptions
    defaultAvailableToRent?: boolean
}

class UnitGrid extends Component<IProps, IState> {
    public grid: any
    public topRowRef: any

    shouldComponentUpdate(nextProps, nextState) {
        // If the refresh param is present then perform a refresh
        let newQuery = queryString.parse(
            nextProps.location.search
        ).refreshUnitGrid
        let previousQuery = queryString.parse(
            this.props.location.search
        ).refreshUnitGrid

        if (newQuery == 'true' && previousQuery != 'true') {
            let removeQuery = removeURLParameter(
                nextProps.location.search,
                'refreshUnitGrid'
            )
            this.props.history.push({
                search: removeQuery,
            })

            return true
        }

        if (this.state.availableToRentOnly != nextState.availableToRentOnly) {
            return true
        }

        if (this.state.LOADED === false && nextState.LOADED === true) {
            return true
        }

        return false
    }

    constructor(props: any) {
        super(props)

        this.topRowRef = createRef()
        this.grid = createRef()

        this.state = {
            loading: false,
            selectedType: '',
            initialRender: false,
            hasBeenGrouped: false,
            search: '',
            deletingUserIds: [],
            deleting: false,
            availableToRentOnly: this.props.defaultAvailableToRent,
            LOADED: false,
            hideGrid: false,
        }
        ;(window as any).customGrouping = function (data) {
            if (data.field === 'propertyId') {
                if (data.items.records) {
                    return `${data.headerText}: ${
                        data.items.records[0].propertyName
                    } - ${data.count} item${data.count > 1 ? 's' : ''}`
                }
                return `${data.headerText}: ${data.items[0].propertyName} - ${
                    data.count
                } item${data.count > 1 ? 's' : ''}`
            }
            return `${data.headerText}: ${data.key} - ${data.count} item${
                data.count > 1 ? 's' : ''
            }`
        }

        this.externalSearchHandler = this.externalSearchHandler.bind(this)
        this.externalExportHandler = this.externalExportHandler.bind(this)
        this.dataBound = this.dataBound.bind(this)
        this.requestDataSource = this.requestDataSource.bind(this)
        this.onDateRangeChange = this.onDateRangeChange.bind(this)
        this.deleteRow = this.deleteRow.bind(this)
        this.toggleAvailableToRentOnly =
            this.toggleAvailableToRentOnly.bind(this)
        this.groupSettings = this.groupSettings.bind(this)
        this.customCell = this.customCell.bind(this)
    }

    groupSettings(): GroupSettingsModel {
        return {
            showGroupedColumn: false,
            captionTemplate:
                "<span class='groupItems'> ${customGrouping(data)}</span>",
            columns:
                this.props.config && this.props.config.groupSettings
                    ? this.props.config.groupSettings
                    : [],
        }
    }

    dataBound(args: any): void {}

    externalExportHandler(exportType: ExportTypeEnum): void {
        if (!this.grid) {
            return
        }

        if (!this.grid.current) {
            return
        }

        const { current } = this.grid

        let selectedRecords = current.getSelectedRecords()
        if (!selectedRecords) {
            return
        }

        if (exportType == ExportTypeEnum.PDF) {
            current.pdfExport()
            return
        }

        if (exportType == ExportTypeEnum.Excel) {
            current.excelExport()
            return
        }

        if (exportType == ExportTypeEnum.Csv) {
            current.csvExport()
            return
        }
    }

    externalSearchHandler(searchValue: string): void {
        this.setState({
            search: searchValue,
        })
    }

    toggleAvailableToRentOnly(availableStatus: boolean): void {
        this.setState({
            availableToRentOnly: !this.state.availableToRentOnly,
        })
    }

    customCell(args: QueryCellInfoEventArgs) {
        if ((args.column as Column).field === 'action') {
            let props = args.data as any
            ReactDOM.render(
                <Router history={history}>
                    <SFDropdown
                        loadedBeforeRender
                        customCssClassName={styles.syncfusionDropdown}
                        onSelect={(args) => {
                            if (args.item.text == 'Edit') {
                                goToRoute(
                                    `/dashboard/unit/view/${props.unitId}?updateRedirectUrl=${this.props.location.pathname}`
                                )
                            }
                            if (args.item.text == 'Add Expense') {
                                onAddUnitTransactionClick(
                                    props.propertyId,
                                    props.propertyName,
                                    props.unitId,
                                    props.name,
                                    this.props.location.search,
                                    false,
                                    true
                                )
                            }
                            if (args.item.text == 'Add Income') {
                                onAddUnitTransactionClick(
                                    props.propertyId,
                                    props.propertyName,
                                    props.unitId,
                                    props.name,
                                    this.props.location.search,
                                    true,
                                    false
                                )
                            }
                            if (args.item.text == 'Add Tenancy') {
                                onAddUnitTenancyClick(
                                    props.unitId,
                                    props.unitName,
                                    props.propertyId,
                                    props.propertyName,
                                    this.props.location.search
                                );
                            }
                            if (args.item.text == 'Add Document') {
                                addDocumentClicked(
                                    {
                                        tenancyId: props.tenancyId,
                                        contactId: props.contactId,
                                        propertyId: props.propertyId,
                                        unitId: props.unitId,
                                    },
                                    'uploadDocumentOptions'
                                )
                            }
                            if (args.item.text == 'Add Issue') {
                                onAddIssueClickGeneric(
                                    {
                                        tenancyId: props.tenancyId,
                                        contactId: props.contactId,
                                        contactName: props.tenantName,
                                        propertyId: props.propertyId,
                                        propertyName: props.propertyName,
                                        unitId: props.unitId,
                                        unitName: props.name,
                                    },
                                    'issueOptions'
                                )
                            }
                            if (args.item.text == 'Linked Property') {
                                goToRoute(
                                    `/dashboard/property/${props.propertyId}`
                                )
                            }
                            if (args.item.text == 'Linked Contact') {
                                goToRoute(
                                    `/dashboard/contact/${props.contactId}`
                                )
                            }
                            if (args.item.text == 'Go to Documents') {
                                goToRoute(
                                    `/dashboard/unit/view/${props.unitId}/documents`
                                )
                            }
                            if (args.item.text == 'Go to Money') {
                                goToRoute(
                                    `/dashboard/unit/view/${props.unitId}/financials`
                                )
                            }
                            if (args.item.text == 'Delete') {
                                onDeleteRows(
                                    [props.unitId],
                                    this.props.location,
                                    this.props.history,
                                    `/dashboard/property/${props.propertyId}/spec/units`
                                )
                            }
                        }}
                        items={[
                            {
                                iconCss: styles.subIconEdit,
                                text: 'Edit',
                                url: `/dashboard/unit/view/${props.unitId}`,
                            },
                            {
                                separator: true,
                            },
                            {
                                iconCss: styles.subIconAddDocument,
                                text: 'Add Expense',
                            },
                            {
                                iconCss: styles.subIconAddTenancyContract,
                                text: 'Add Income',
                            },
                            {
                                iconCss: styles.subIconAddDocument,
                                text: 'Add Document',
                            },
                            {
                                iconCss: styles.subIconAddIssue,
                                text: 'Add Issue',
                            },
                            {
                                iconCss: styles.subIconTenancy,
                                text: 'Add Tenancy',
                            },
                            {
                                separator: true,
                            },
                            ...(props.propertyId
                                ? [
                                      {
                                          iconCss: styles.subIconProperty,
                                          text: 'Linked Property',
                                          url: `/dashboard/property/${props.propertyId}`,
                                      },
                                  ]
                                : []),
                            {
                                iconCss: styles.iconGoToFinancials,
                                text: 'Go to Money',
                                url: `/dashboard/unit/view/${props.unitId}/financials`,
                            },
                            {
                                iconCss: styles.subIconGoToDocuments,
                                text: 'Go to Documents',
                                url: `/dashboard/unit/view/${props.unitId}/documents`,
                            },
                            {
                                iconCss: styles.subIconDelete,
                                text: 'Delete',
                            },
                        ]}
                    />
                </Router>,
                args.cell as Element
            )
        }

        if ((args.column as Column).headerText === 'Tenant') {
            let data = args.data as any
            if (
                data.latestTenantContactId &&
                data.latestTenantContactId != null
            ) {
                ReactDOM.render(
                    <Router history={history}>
                        <UserContactInfo
                            id={data.latestTenantContactId}
                            type={UserContactInfoType.Contact}
                        />
                    </Router>,
                    args.cell as Element
                )
            } else {
                ReactDOM.render(<div>None</div>, args.cell as Element)
            }
        }
    }

    componentDidMount(): void {
        let availableToRent = queryString.parse(
            this.props.location.search
        ).availableToRent
        if (availableToRent === 'true') {
            this.setState({
                availableToRentOnly: true,
            })
        }

        let removeQuery = removeURLParameter(
            this.props.location.search,
            'availableToRent'
        )
        this.props.history.push({
            search: removeQuery,
        })

        if (!this.state.LOADED) {
            this.setState({
                LOADED: true,
            })
        }
    }

    requestDataSource(): void {
        if (!this.grid || !this.grid.current) {
            return
        }

        const { current } = this.grid

        current.dataSource = generateOdataSource(
            {
                size: 0,
                page: 0,
                search: this.state.search,
                propertyId:
                    this.props.config &&
                    this.props.config.dataFilterSettings &&
                    this.props.config.dataFilterSettings.propertyId,
                availableToRentOnly: this.state.availableToRentOnly,
            },
            this.state.startDate,
            this.state.endDate
        )
    }

    deleteRow(): void {
        this.requestDataSource()
    }

    onDateRangeChange(startDate: Date, endDate: Date): void {
        this.setState(
            {
                startDate,
                endDate,
            },
            () => {
                this.requestDataSource()
            }
        )
    }

    contextMenuClickedDelete() {
        if (!this.grid || !this.grid.current) {
            return
        }

        let rows = this.grid.current.getSelectedRecords()

        if (rows.length == 0) {
            updateToastMessage('No rows have been selected.', 'warning')
            return
        }

        let unitIds = []
        for (let i = 0; i < rows.length; i++) {
            unitIds.push((rows[i] as any).unitId)
        }

        onDeleteRows(
            unitIds,
            this.props.location,
            this.props.history,
            this.props.onDeleteCallbackUrl
        )
    }

    render() {
        if (!this.state.LOADED) {
            return <></>
        }

        return (
            <div className={styles.page}>
                <div className={styles.content}>
                    <div className={`${styles.row}`}>
                        <div className={styles.column}>
                            <div className={styles.left}>
                                <div className={styles.dropdown}>
                                    <div className={styles.searchInput}>
                                        <TextBoxComponent
                                            change={(args: ChangedEventArgs) =>
                                                this.externalSearchHandler(
                                                    args.value
                                                )
                                            }
                                            placeholder="Search Units"
                                        />
                                    </div>
                                </div>

                                <div className={styles.checkbox}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    this.state
                                                        .availableToRentOnly
                                                }
                                                color="primary"
                                                onChange={() => {
                                                    this.setState({
                                                        availableToRentOnly:
                                                            !this.state
                                                                .availableToRentOnly,
                                                    })
                                                }}
                                                value={
                                                    this.state
                                                        .availableToRentOnly
                                                }
                                            />
                                        }
                                        label="Available to rent?"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.iconColumn}>
                            <SFDropdown
                                loadedBeforeRender
                                customCssClassName={styles.syncfusionDropdown}
                                customToggle={
                                    <IconButton
                                        button={{
                                            text: 'Actions',
                                            displayType: 'action',
                                            elementType: 'button',
                                            icon: 'action',
                                        }}
                                    />
                                }
                                items={[
                                    {
                                        iconCss: styles.subIconAdd,
                                        text: 'Add Unit',
                                    },
                                    ...(this.props.onMultiAdd
                                        ? [
                                              {
                                                  iconCss:
                                                      styles.subIconAddDocument,
                                                  text: 'Multi Add',
                                              },
                                          ]
                                        : []),
                                    {
                                        iconCss: styles.subIconDelete,
                                        text: 'Delete',
                                    },
                                    {
                                        separator: true,
                                    },
                                    {
                                        iconCss: styles.subIconExportExcel,
                                        text: 'Export to Excel',
                                    },
                                    {
                                        iconCss: styles.subIconExportCsv,
                                        text: 'Export to CSV',
                                    },
                                    {
                                        iconCss: styles.subIconExportPdf,
                                        text: 'Export to PDF',
                                    },
                                ]}
                                onSelect={(args) => {
                                    if (args.item.text === 'Add Unit') {
                                        this.props.onAdd && this.props.onAdd()
                                    }
                                    if (args.item.text === 'Multi Add') {
                                        this.props.onMultiAdd &&
                                            this.props.onMultiAdd()
                                    }
                                    if (args.item.text === 'Delete') {
                                        this.contextMenuClickedDelete()
                                    }
                                    if (args.item.text === 'Export to PDF') {
                                        this.externalExportHandler(
                                            ExportTypeEnum.PDF
                                        )
                                    }
                                    if (args.item.text === 'Export to Excel') {
                                        this.externalExportHandler(
                                            ExportTypeEnum.Excel
                                        )
                                    }
                                    if (args.item.text === 'Export to CSV') {
                                        this.externalExportHandler(
                                            ExportTypeEnum.Csv
                                        )
                                    }
                                }}
                            />
                        </div>

                        <div
                            className={`${styles.iconColumn} ${styles.addIcons}`}
                        >
                            {this.props.onMultiAdd && (
                                <IconButton
                                    button={{
                                        text: 'Multi Add',
                                        displayType: 'submit',
                                        elementType: 'button',
                                        icon: 'add',
                                        onClick: () => this.props.onMultiAdd(),
                                    }}
                                />
                            )}
                            {
                                <IconButton
                                    button={{
                                        text: 'Add Unit',
                                        displayType: 'submit',
                                        elementType: 'button',
                                        icon: 'add',
                                        onClick: () => this.props.onAdd(),
                                    }}
                                />
                            }
                        </div>
                    </div>

                    <div className={`${styles.row}`}>
                        <div className={`${styles.column} ${styles.grid}`}>
                            <GridComponent
                                actionBegin={(action) =>
                                    console.log('ACTION', action)
                                }
                                groupSettings={this.groupSettings()}
                                queryCellInfo={this.customCell}
                                editSettings={editSettings}
                                showColumnMenu
                                id="unitGrid"
                                textWrapSettings={textWrapSettings}
                                allowTextWrap={true}
                                allowExcelExport={true}
                                allowPdfExport={true}
                                allowGrouping={true}
                                dataSource={generateOdataSource(
                                    {
                                        size: 0,
                                        page: 0,
                                        search: this.state.search,
                                        propertyId:
                                            this.props.config &&
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .propertyId,
                                        availableToRentOnly:
                                            this.state.availableToRentOnly,
                                    },
                                    this.state.startDate,
                                    this.state.endDate
                                )}
                                allowResizing={true}
                                allowReordering={true}
                                selectionSettings={selectionSettings}
                                allowPaging={true}
                                allowSorting={true}
                                sortSettings={{
                                    columns: [
                                        {
                                            field: 'name',
                                            direction: 'Ascending',
                                        },
                                    ],
                                }}
                                pageSettings={pageSettings}
                                filterSettings={filterOptions}
                                allowFiltering={true}
                                ref={this.grid}
                            >
                                <Inject
                                    services={[
                                        ColumnMenu,
                                        ContextMenu,
                                        CommandColumn,
                                        Filter,
                                        Page,
                                        Sort,
                                        ExcelExport,
                                        PdfExport,
                                        Group,
                                        Reorder,
                                        Resize,
                                    ]}
                                />

                                {this.props.config && this.props.config.template
                                    ? this.props.config.template
                                    : UnitDefaultTemplate(this.props, {
                                          updateRedirectUrl: '/dashboard/units',
                                          addRedirectUrl: '/dashboard/units',
                                          cancelRedirectUrl: '/dashboard/units',
                                      })}
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(UnitGrid)
